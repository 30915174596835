/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import url from '../../link'
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      
    <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
    <CustomDropdown
          buttonText="Services"
          style={{backgroundColor:'#196c90'}}
          className={classes.navLink}
      
          dropdownList={[
            <NavLink to="/" className={classes.dropdownLink}>
            Insert Receiver
            </NavLink>
            ,
            <NavLink to="/View_Sample_Tracking" className={classes.dropdownLink}>
  View Sample Tracking
  </NavLink>,
  <NavLink to="/OverAllUpdate" className={classes.dropdownLink}>
  Update Tracking
  </NavLink>,
  <NavLink to="/DeleteByID" className={classes.dropdownLink}>
  Delete Sampling Record
  </NavLink>
          ]}
           />
    </ListItem>
    </List>
  );
}
