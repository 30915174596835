import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import moment from 'moment';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/9440.jpg";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TextField from '@material-ui/core/TextField';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function Sample_Tracking(props) {
    const [tracking_id_search,setTrackingID_Search]=React.useState('')
    const [tracking_id,setTrackingID]=React.useState('')
    const [car_no, setCarNo] = React.useState('')
    const [driver_no, setDriverNo] = React.useState('')
    const [Total_Sample, setTotal_Sampling] = React.useState(0)
    const [Urgent_Sample, setUrgent_Sampling] = React.useState(0)
    const [sender, setSender] = React.useState('')
    const [branch, setBranch] = React.useState('Select')
    const [arrival_date, setArrival_Date] = React.useState('')
    const [booking_date, setBooking_Date] = React.useState('')
    const [branchDetail, setBranchDetail] = React.useState([])
    const yesterday = moment().subtract(1, 'day');
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [classicModal, setClassicModal] = React.useState(false);
    const [ServerResponse, setServerResponse] = React.useState('')
    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    setTimeout(function () {
        setCardAnimation("");
    }, 700);
    const classes = useStyles();
    const { ...rest } = props;
    const [reports, setReports] = React.useState('')

    return (
                <div>
                    <Header
                        absolute
                        color="transparent"
                        brand="Material Kit React"
                        rightLinks={<HeaderLinks />}
                        {...rest}
                    />
                    <div
                        className={classes.pageHeader}
                        style={{
                            backgroundImage: "url(" + image + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "top center"
                        }}
                    >
                        <div className={classes.container}>

                            <Card className={classes[cardAnimaton]}>
                                <form className={classes.form}>
                                    <CardHeader className={classes.cardHeader} style={{ backgroundColor: '#196c90' }}>
                                        <h4 style={{ color: 'white' }}>Update Sample Tracking</h4>

                                    </CardHeader>
                                    <CardBody>
                                    <GridContainer>
                      <GridItem xs={12} xs={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Searching Tracking ID"
                  value={tracking_id_search}
                  onChange={(e)=>{
                    setTrackingID_Search(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                  <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <Button color="#196c90" size="lg" style={{backgroundColor:'#196c90'}}
                     onClick={async()=>{
                         fetch("https://mpl-labs.felpower.com.pk/SearchbyID",{
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                trackingID:tracking_id_search
                            }),

                         }).then((res)=>res.json()).then((response)=>{
                            if(response.length!=0){
                                setTrackingID(response.value[0].tracking_id)
                                setCarNo(response.value[0].car_no)
                                setDriverNo(response.value[0].driver_no)
                                setSender(response.value[0].sender)
                                setTotal_Sampling(response.value[0].total_sample)
                                setUrgent_Sampling(response.value[0].urgent_sample)
                                setBooking_Date(new moment(response.value[0].booking_date))
                                setArrival_Date(new moment(response.value[0].arrival_date))
                            }
                         })
                     }}
                     >Search</Button>
                  </GridItem>
                  
                  </GridContainer>
                  <br />
                                        <br />
                  <GridContainer>
                                            <GridItem xs={12} xs={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Tracking ID"
                                                    value={tracking_id}
                                                    disabled

                                                />
                                            </GridItem>
                                            <GridItem xs={0} sm={0} md={4} lg={4}>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                              
                                            </GridItem>

                                        </GridContainer>
                                        <br />
                                        <br />
                                        <GridContainer>
                                            <GridItem xs={12} xs={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Sender Name"
                                                    value={sender}
                                                    onChange={(e) => {
                                                        setSender(e.target.value)
                                                    }}

                                                />
                                            </GridItem>
                                            <GridItem xs={0} sm={0} md={4} lg={4}>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Car No / Courier Company Name"
                                                    value={car_no}
                                                    onChange={(e) => {
                                                        setCarNo(e.target.value)
                                                    }}

                                                />
                                            </GridItem>

                                        </GridContainer>
                                        <br />
                                        <br />
                                        <GridContainer fullWidth>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <Datetime
                                                        inputProps={{ placeholder: "Arrival Date" }}
                                                        value={arrival_date}

                                                        required
                                                        onChange={(e) => {
                                                            setArrival_Date(e)
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>

                                            <GridItem xs={0} sm={0} md={4} lg={4}>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <Datetime block
                                                        inputProps={{ placeholder: "Booking Date & Time" }}
                                                        value={booking_date}
                                                        required
                                                        onChange={(e) => {
                                                            setBooking_Date(e)
                                                        }}
                                                    /></FormControl>
                                            </GridItem>
                                        </GridContainer>
                                        <br />

                                        <br />
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Driver No/ Tracking No of Courier"
                                                    type="text"
                                                    value={driver_no}
                                                    onChange={(e) => {
                                                        setDriverNo(e.target.value)
                                                    }}

                                                />
                                            </GridItem>

                                            <GridItem xs={0} sm={0} md={4} lg={4}>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Total Samples"
                                                    type="number"
                                                    value={Total_Sample}
                                                    onChange={(e) => {
                                                        setTotal_Sampling(e.target.value)
                                                    }}

                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <br />
                                        <br />
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                                <TextField required id="" block
                                                    fullWidth={true}
                                                    label="Urgent Number of Samples"
                                                    type="number"
                                                    value={Urgent_Sample}
                                                    onChange={(e) => {
                                                        setUrgent_Sampling(e.target.value)
                                                    }}

                                                />
                                            </GridItem>

                                            <GridItem xs={0} sm={0} md={4} lg={4}>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} lg={4}>

                                            </GridItem>
                                        </GridContainer>

                                        <br />

                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button color="#196c90" size="lg" style={{ backgroundColor: '#196c90' }}
                                            onClick={async () => {
                                                var driver_num = driver_no + ""
                                                var track=tracking_id+""
                                                if (sender.trim() == '') {
                                                    setClassicModal(true)
                                                    setServerResponse('Kindly Enter Sender Name !!')
                                                    return
                                                }
                                                if (driver_num.trim() == '') {
                                                    setClassicModal(true)
                                                    setServerResponse('Kindly Enter Driver Number !!')
                                                    return
                                                }
                                                if (car_no.trim() == '') {
                                                    setClassicModal(true)
                                                    setServerResponse('Kindly Select Car No !!')
                                                    return
                                                }

                                                if (arrival_date == '') {
                                                    setClassicModal(true)
                                                    setServerResponse('Invalid Arrival Date & Time!')
                                                    return
                                                }
                                                if (booking_date == '') {
                                                    setClassicModal(true)
                                                    setServerResponse('Invalid Booking Date & Time!')
                                                    return
                                                }
                                                if (Total_Sample < 1) {
                                                    setClassicModal(true)
                                                    setServerResponse('Invalid Samples!')
                                                    return
                                                }
                                                if (track.trim()=='') {
                                                    setClassicModal(true)
                                                    setServerResponse('Invalid Tracking ID!')
                                                    return
                                                }
                                                if (parseInt(Total_Sample) < parseInt(Urgent_Sample)) {
                                                    setClassicModal(true)
                                                    setServerResponse('Total Samples must be greater than Urgent Samples!')
                                                    return
                                                }

                                                var data = {
                                                    tracking_id:tracking_id,
                                                    arrival_date: arrival_date.toISOString(),
                                                    booking_date: booking_date.toISOString(),
                                                    arrival_time: formatAMPM(arrival_date._d),
                                                    booking_time: formatAMPM(booking_date._d),
                                                    car_no: car_no,
                                                    driver_no: driver_num,
                                                    total_sample: Total_Sample,
                                                    urgent_sample: Urgent_Sample,
                                                    sender: sender

                                                }
                                                fetch('https://mpl-labs.felpower.com.pk/UpdateOverAll', {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(data),

                                                }).then((response) => {
                                                    return response.json();
                                                }).then((res) => {
                                                    if (res.value == "Successful") {
                                                        setClassicModal(true)
                                                        setServerResponse('Sampling Tracking Updated!!')
                                                        setTimeout(() => {
                                                            window.location.reload()
                                                        }, 3000)
                                                        return
                                                    } else {
                                                        setClassicModal(true)
                                                        setServerResponse('Sorry , Failed to Add!!')
                                                        return

                                                    }
                                                })


                                            }}
                                        >
                                            Submit
                    </Button>
                                    </CardFooter>
                                </form>
                            </Card>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={4}>

                                    <Dialog
                                        classes={{
                                            root: classes.center,
                                            paper: classes.modal
                                        }}
                                        open={classicModal}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={() => setClassicModal(false)}
                                        aria-labelledby="classic-modal-slide-title"
                                        aria-describedby="classic-modal-slide-description"
                                    >
                                        <DialogTitle
                                            id="classic-modal-slide-title"
                                            disableTypography
                                            className={classes.modalHeader}
                                        >

                                            <h4 className={classes.modalTitle}><b>Sample Tracking</b></h4>
                                        </DialogTitle>
                                        <DialogContent
                                            id="classic-modal-slide-description"
                                            className={classes.modalBody}
                                        >
                                            <p>
                                                {ServerResponse}
                                            </p>
                                        </DialogContent>
                                        <DialogActions className={classes.modalFooter}>

                                            <Button
                                                onClick={() => setClassicModal(false)}
                                                color="danger"
                                                simple
                                            >
                                                Ok
                    </Button>
                                        </DialogActions>
                                    </Dialog>
                                </GridItem>
                            </GridContainer>

                        </div>

                    </div>
                </div> 
    );
}
