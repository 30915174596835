import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import View_Sample_Tracking from 'views/View_Sample_Tracking/View_Sample_Tracking';

// pages for this product
import Update_Sample_Tracking from 'views/Update_Sampling_Record/Update_Sampling_Record'

import OverAllUpdate from 'views/OverAllUpdate/OverAllUpdate'

import DeleteSampling from 'views/DeleteSampling/DeleteSampling'

var hist = createBrowserHistory();

ReactDOM.render(
 <BrowserRouter history={hist}>
 <Switch>
  
<Route exact path="/" component={Update_Sample_Tracking} /> 
<Route path="/View_Sample_Tracking" component={View_Sample_Tracking} /> 
<Route path="/OverAllUpdate" component={OverAllUpdate} /> 
<Route path="/DeleteByID" component={DeleteSampling} /> 
 </Switch>
</BrowserRouter>

,
  document.getElementById("root")
);
