import React,{useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// core components
import Header from "components/Header/Header.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/9440.jpg";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function Sample_Tracking(props) {

const [Receiver,setReceiver]=React.useState('')
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [classicModal, setClassicModal] = React.useState(false);
  const [ServerResponse,setServerResponse]=React.useState('')
  const [Tracking,setTrackingID]=React.useState('Select')
  const [trackDetail,setTrackingDetail]=React.useState([])
  const [reports,setReports]=React.useState('')
  useEffect(()=>{
    fetch("https://mpl-labs.felpower.com.pk/getBranchWise?branch=branch"
    ).then((res)=>res.json())
     .then((result)=>{
         var the_rows=[]
       result.map((item)=>{
         if(item.receiver==null){
          the_rows.push({
            branch:item.branch,
            trackingid:item.tracking_id
          })
         }
       })
       setTrackingDetail(the_rows)
      })
  
  },[])

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

useEffect(()=>{

},[])

  return (
   
      <div>
        <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        {...rest}
        rightLinks={<HeaderLinks />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'#196c90'}}>
                    <h4 style={{color:'white'}}>Insert Receiver</h4>
                    
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                      <GridItem xs={12} xs={12} md={4} lg={4}>
                      <FormControl variant="filled" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="filled-age-native-simple">Tracking ID</InputLabel>
            <Select
              native
              value={Tracking}
              onChange={(event)=>{
                const value = event.target.value;
                setTrackingID(value)
              }}
              inputProps={{
                name: 'status',
                id: 'filled-age-native-simple',
              }}
            >
              <option aria-label="Select" value="All" >All</option>
             {
               trackDetail.map((value)=>{
               return <option aria-label="Select" value={value.trackingid} >{value.trackingid+" "+value.branch}</option>
               })
             }
            </Select>
          </FormControl>
                  
                  </GridItem>
                  <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Receiver Name"
                  value={Receiver}
                  onChange={(e)=>{
                    setReceiver(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                  
                  </GridContainer>
                  

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#196c90" size="lg" style={{backgroundColor:'#196c90'}}
                     onClick={async()=>{
                      
                    if(Tracking=='Select'){
                        setClassicModal(true)
                        setServerResponse('Kindly Select Tracking ID !!')
                        return
                       }
                       if(Receiver.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Receiver Name !!')
                        return
                       }
                       
                  var data={
                    trackingID:Tracking,
                    receiver:Receiver
                  }
                      console.log(data)
                       fetch('https://mpl-labs.felpower.com.pk/updateTracking',{
                       method: 'POST',
                       headers:{
                           'Content-Type':'application/json'
                       },
                       body: JSON.stringify(data),  
                    
                    }).then((response) => {
                          return response.json();
                       }).then((res)=>{
                        if(res.value=="Successful"){
                          setClassicModal(true)
                          setServerResponse('Sampling Tracking Record Updated!!')
                          setTimeout(()=>{
                            window.location.reload()
                          },3000)
                        return
                        }
                       {
                          setClassicModal(true)
                          setServerResponse('Sorry , Failed to Updated!!')
                          return
                      
                         }
                      })
                       
                       
                     }}
                    >
                      Submit
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            
         
          <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Patient Registration</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>

        </div>
       
      </div></div>
 
  );
}
